<template>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn
                        v-on="on"
                        v-bind="$attrs"
                        @click="click"
                        :disabled="!isActiveEvent"
                >
                    <v-icon>{{ options.icon }}</v-icon>
                </v-btn>
                <capture-screen :el="element" :messageType="messageType" ref="capture" @onSend="onSend"></capture-screen>
            </template>
            Odeslat na výjezdový telefon
        </v-tooltip>
</template>
<script>

import {mapGetters} from "vuex";

export default {
    name: "CaptureScreenButton",
    components: {
        CaptureScreen: () => import("@/components/CaptureScreen"),
    },
    props: {
        messageType: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            default: () => {
                return {
                    icon: 'send',
                }
            }
        },
        el: {
            required: true,
        }
    },
    data() {
        return {
            element: null,
        }
    },
    computed: {
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
        }),
    },
    methods: {
        click() {
            this.element = this.el.$el !== undefined ? this.el.$el : this.el;
            this.$refs.capture.start();
        },
        onSend(message) {
            this.$emit('onSend', message);
        }
    },
};
</script>
